@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&family=Josefin+Sans:wght@100&family=Nova+Round&display=swap');

$font-1: 'Nova Round', cursive;
$font-2: 'Dosis', sans-serif;
$font-3: 'Josefin Sans', serif;

$color-1: #14213d;
$color-2: #00737E;
$color-3: #f1f1f1;
$color-4: #aec7ce;
$color-5: #ddd;
$white: #ddd;
$black: #333;

$borderRadius: 25px;
$shadow: 0 1px 3px #333;
$minHeight: 95vh;

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center; 
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  margin: 1rem auto;
  max-width: 1200px;
  font-family: $font-1;
  color: $black;
}
button {
  cursor: pointer;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: $black;
  cursor: pointer;
}
a:visited {
  color:$black;
}
.home, .knowledges, .portfolio, .contact {
  display: flex;

  @media screen and (max-width: 850px){
    display: block !important;
  }
}
.homeContent, .knowledgesContent, .portfolioContent, .contactContent {
  margin-left: .4rem;

  @media screen and (max-width: 850px){
    margin-left: 0 !important;
  }
}